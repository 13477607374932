#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
}

body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* --------------------------- Inter Font ---------------------------- */

/* Load variable Inter font file : All font weights and styles */
@font-face {
  font-family: "Inter";
  src: url("./fonts/inter/InterVariable.ttf");
  font-display: swap;
}

/* ------------------------- Custom styles ------------------------ */

/* width */
.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.thin-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.thin-scrollbar::-webkit-scrollbar-thumb {
  background: #222;
  border-radius: 10px;
}

/* Handle on hover */
.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #222;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

[contenteditable]:focus {
  outline: 0px solid transparent;
  line-height: 15px;
}

input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

.bg-add-tasks {
  background-image: url("./assets/svgs/add-tasks-bg.svg");
}
